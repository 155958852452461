import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import get from "lodash/get"
import countBy from "lodash/countBy"
import orderBy from "lodash/orderBy"
import { useDispatch } from "react-redux"
import { gsap, SplitText } from "gsap/all"

import { setState, setDuration } from "../store/transition"
import { percentage } from "../utilities/functions"
import BaseImage from "../components/BaseImage"
import BaseFigureCaseStudy from "../components/BaseFigureCaseStudy"

if (typeof window !== "undefined") {
  gsap.registerPlugin(SplitText)
}

export default function ArchiveCaseStudy() {
  const data = useStaticQuery(graphql`
    query {
      allDataJson(sort: { fields: order }, filter: { theme: { ne: null } }) {
        edges {
          node {
            fields {
              slug
            }
            title
            tags
            theme
            images {
              foreground {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              background {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              featured {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
            }
            backgroundText
          }
        }
      }
    }
  `)
  const [currentTag, setCurrentTag] = useState(null)
  const setItemClicked = tag => {
    setCurrentTag(tag)
  }

  return (
    <>
      <ArchiveCaseStudyFilters data={data} currentTag={currentTag} onItemClick={setItemClicked} />
      <ArchiveCaseStudyCollection data={data} currentTag={currentTag} />
    </>
  )
}

function ArchiveCaseStudyFilters({ data, currentTag, onItemClick }) {
  const tags = data.allDataJson.edges.map(item => {
    return item.node.tags
  })
  const tagsTotal = tags.length
  const tagsFlat = tags.flat()
  const tagsCount = countBy(tagsFlat)
  const tagsEntries = Object.entries(tagsCount)
  let items = tagsEntries.map(([key, value]) => {
    return {
      name: key,
      count: value,
    }
  })
  items = orderBy(items, ['name'])
  const theme = useTheme()

  css.section = css`
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "150px",
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "paddingTop",
        fromSize: "150px",
        toSize: "200px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "32px",
        toSize: "50px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;

    display: flex;
    overflow-x: auto;
    padding-top: 16px;
    padding-bottom: 12px;
  `

  css.grid = css`
    display: flex;
    align-items: center;
    margin: auto;
  `

  css.gridItem = css`
    display: inline-flex;
    align-items: center;
    grid-row: 1;

    &:first-of-type {
      margin-left: auto;
    }

    &:last-of-type {
      margin-right: auto;
    }

    &:not(:first-of-type) {
      &:before {
        content: "-";
        ${fluidRange(
          {
            prop: "marginRight",
            fromSize: "15px",
            toSize: "30px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
        ${fluidRange(
          {
            prop: "marginLeft",
            fromSize: "15px",
            toSize: "30px",
          },
          theme.breakpoints.mobile,
          theme.breakpoints.desktop
        )}
      }
    }
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          <div css={css.gridItem}>
            <ArchiveCaseStudyFiltersItem
              name="All"
              count={tagsTotal}
              isActive={currentTag === null}
              onItemClick={() => {
                onItemClick(null)
              }}
            />
          </div>
          {items.map((item, index) => (
            <div key={index} css={css.gridItem}>
              <ArchiveCaseStudyFiltersItem
                key={index}
                {...item}
                isActive={currentTag === item.name}
                onItemClick={onItemClick}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

function ArchiveCaseStudyFiltersItem({ name, count, isActive, onItemClick }) {
  const themeGlobal = useTheme()

  css.item = css`
    ${themeGlobal.mixins.button}
    position: relative;
    font-weight: ${themeGlobal.fontWeight.semiBold};
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &:hover {
      color: ${themeGlobal.colors.typeAndTixel.one};

      &:before {
        transform: scaleX(1);
      }
    }

    ${isActive && css`
      color: ${themeGlobal.colors.typeAndTixel.one};

      &:before {
        transform: scaleX(1);
      }
    `}

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      display: block;
      height: 5px;
      margin-top: 6px;
      background-color: ${themeGlobal.colors.typeAndTixel.one};
      transform: scaleX(0);
      transform-origin: left;
      transition-property: transform;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;

      ${isActive && css`
        transform: scaleX(1);
      `}
    }
  `

  css.count = css`
    position: absolute;
    bottom: 100%;
    left: 100%;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "14px",
        toSize: "14px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    color: ${themeGlobal.colors.typeAndTixel.one};
    opacity: 0.5;
  `

  return (
    <button
      css={css.item}
      onClick={() => {
        onItemClick(name)
      }}
    >
      {name}
      <span css={css.count}>{count}</span>
    </button>
  )
}

function ArchiveCaseStudyCollection({ data, currentTag }) {
  const items = data.allDataJson.edges.map(item => {
    return {
      slug: item.node.fields.slug,
      title: item.node.title,
      tags: item.node.tags,
      theme: item.node.theme,
      imageForeground: get(item, "node.images.foreground.src"),
      imageBackground: get(item, "node.images.background.src"),
      imageFeatured: get(item, "node.images.featured.src"),
    }
  })
  const itemsOriginal = useRef(items)
  const theme = useTheme()
  const [itemsFiltered, setItemsFiltered] = useState(itemsOriginal.current)

  useEffect(() => {
    setItemsFiltered(
      currentTag
        ? itemsOriginal.current.filter(item => {
            return item.tags.includes(currentTag)
          })
        : itemsOriginal.current
    )
  }, [currentTag, itemsOriginal])

  css.section = css`
    overflow: hidden;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "50px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "50px",
        toSize: "100px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.container = css`
    ${theme.mixins.container.xl}
    max-width: none;
  `

  css.grid = css`
    display: grid;
    grid-column-gap: ${theme.grid.gutter};
    grid-row-gap: ${theme.grid.gutter};

    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-template-columns: repeat(3, 1fr);
    }
  `

  css.gridItem = css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      grid-column: span 1;
    }

    &:nth-of-type(1), &:nth-of-type(7), &:nth-of-type(11){
      @media (min-width: ${theme.breakpoints.tablet}) {
        grid-column: span 2;

        > a {
          &:before {
            padding-bottom: ${percentage(640, 1200)};
          }
        }
      }
    }

    > a {
      &:before {
        padding-bottom: ${percentage(640, 583)};
      }
    }
  `

  return (
    <section css={css.section}>
      <div css={css.container}>
        <div css={css.grid}>
          {itemsFiltered.map((item, index) => (
            <div key={item.theme} css={css.gridItem}>
              <ArchiveCaseStudyItem {...item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

function ArchiveCaseStudyItem({
  slug,
  title,
  theme,
  imageForeground,
  imageBackground,
  imageFeatured,
  backgroundText,
}) {
  const themeGlobal = useTheme()
  const [transition, setTransition] = useState(false)
  const dispatch = useDispatch()
  const ref = useRef(null)
  const refTimeline = useRef(null)
  const handleMouseEnter = () => {
    const timeline = refTimeline.current

    timeline.play()
  }
  const handleMouseLeave = () => {
    const timeline = refTimeline.current

    timeline.reverse()
  }

  useEffect(() => {
    const element = ref.current
    const imageBackground = element.querySelectorAll(".image--background")
    const imageForeground = element.querySelectorAll(".image--foreground")
    const link = element.querySelectorAll(".link")
    const linkUnderline = element.querySelectorAll(".link__underline")
    const heading = element.querySelectorAll(".heading")
    const headingSplit = new SplitText(heading, {
      type: "words, lines",
    })
    const timeline = gsap.timeline({ paused: true })

    timeline
      .fromTo(
        imageForeground,
        { autoAlpha: 1 },
        { autoAlpha: 0, duration: 0.5 },
        0
      )
      .fromTo(imageBackground, { scale: 0.75 }, { scale: 1, duration: 0.5 }, 0)
      .fromTo(
        headingSplit.lines,
        {
          clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          duration: 0.3,
          stagger: 0.05,
        },
        0.15
      )
      .fromTo(link, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.4 }, 0.3)
      .fromTo(
        linkUnderline,
        { scaleX: 0 },
        { scaleX: 1, transformOrigin: "left", duration: 0.4 },
        0.3
      )

    refTimeline.current = timeline

    element.addEventListener("mouseenter", handleMouseEnter)
    element.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter)
      element.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [])

  css.section = css`
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 100%;
    color: ${themeGlobal.colors.global.light};
    background-color: ${themeGlobal.colors[theme].one};

    &:before {
      content: "";
      display: block;
    }
  `

  css.body = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `

  css.content = css`
    position: relative;
  `

  css.heading = css`
    ${themeGlobal.mixins.typography.heading1}
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "40px",
        toSize: "80px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  `

  css.image = css`
    opacity: 0.5;
  `

  css.imageFeatured = css`
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `

  css.link = css`
    position: absolute;
    top: 100%;
    left: 50%;
    display: inline-block;
    margin-top: 50px;
    ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "12px",
        toSize: "12px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    font-weight: ${themeGlobal.fontWeight.semiBold};
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    transform: translateX(-50%);
  `

  css.linkUnderline = css`
    display: block;
    height: 2px;
    margin-top: 6px;
    background-color: currentColor;
  `

  return (
    <Link
      to={slug}
      css={css.section}
      className="cursor cursor--case-study"
      ref={ref}
      onClick={event => {
        // event.preventDefault()
        setTransition(Date.now())
        dispatch(setDuration(1200))
      }}
    >
      <div css={css.body}>
        <BaseFigureCaseStudy
          imageForeground={imageForeground}
          imageBackground={imageBackground}
          theme={theme}
          backgroundText={backgroundText}
          transitionTrigger={transition}
          css={css.image}
          className="image--background"
        />
        <div css={css.content}>
          <h2
            css={css.heading}
            className="heading"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <div css={css.link} className="link">
            View case study
            <span css={css.linkUnderline} className="link__underline" />
          </div>
        </div>
        <BaseImage
          css={css.imageFeatured}
          className="image--foreground"
          src={imageFeatured}
        />
      </div>
    </Link>
  )
}
